/* Footer section */
.footer {
    width: 100%;
    background: #017AB3;
    padding: 40px 0;
    overflow: hidden;
    font-size: 12px;
    color: #fff;
}
.footer a{
    color: #FFF !important;
}
.footer ul{
    padding: 0;
    margin: 0;
    list-style: none;
}
.footer ul li{
    margin-bottom: 10px;
}
.footer h4{
    font-size: 14px !important;
    font-weight: bold;
}
.footer_menu li a {
    color: #ffffff !important;
    font-size: 16px;
}
.footer_contact .social_icon {
    display: block;
    margin: 0;
    overflow: hidden;
    padding: 0;
    width: 100%;
}
.social_icon > li {
    display: inline-block;
    list-style: none;
    border-radius: 100%;
    text-align: center;
    margin: 0 10px 0 0;
}
.social_icon li a {
    padding-left: 0;
    margin-right: 5px;
    background: none;
    text-align: center;
}
#link a {
    color:#ffffff !important; 
    font-size: 14px; 
    font-weight: 300;
}
#link a:visited { 
    color:#ffffff !important; 
}
#link p {
    color: #d6e9f2 !important;
}
#link strong a {
    font-size: 16px;
    font-weight: 800 !important;
}
.copyright {
    color: #d6e9f2;
    margin: 0 auto;
}
.footer-keyword {
    padding: 20px 0;
    border-top: 1px solid #cecece;
    width: 83.33%;
    margin: auto;
    font-size: 12px;
}
.footer-keyword > div {
    color: #848484;
}
.footer-keyword > div span {
    text-transform: uppercase;
    padding-right: 10px;
}
.footer_links {
    color: #d6e9f2;
}
